<template>
  <div class="ied-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      form: {
        date: ""
      },
      opt: {
        title: "智能设备",
        search: [
          {
            key: "type",
            label: "设备类型",
            type: "select",
            opt: {
              list: [
                {
                  value: 2,
                  label: "蓝牙网关"
                },
                {
                  value: 1,
                  label: "中央控制器"
                }
              ]
            }
          },
          {
            key: "status",
            label: "设备状态",
            type: "select",
            opt: {
              list: [
                {
                  value: 1,
                  label: "在线"
                },
                {
                  value: 0,
                  label: "离线"
                }
              ]
            }
          },
          {
            key: "apartmentName",
            label: "房源名称"
          },
          {
            key: "sn",
            label: "设备编号"
          }
        ],
        columns: [
          {
            label: "设备编号",
            key: "sn",
            on: row => {
              _this.$router.push({
                path: "/main/ied/detail/show/",
                query: {
                  sn: row.sn,
                  type: row.type,
                  id: row.id
                }
              });
            }
          },
          {
            label: "设备类型",
            key: "type"
          },
          { label: "房源名称", key: "apartmentName" },
          { label: "安装位置", key: "location" },
          { label: "设备状态", key: "status" }
        ]
      }
    };
  },
  created() {
    console.log("ied-list created!");
  },
  methods: {
    onGet(opt) {
      var dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit
      };
      dto = Object.assign(dto, opt.searchForm);

      this.post("device-service/gateway/getGatewayList", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) { 
          let item = res.data.data[i];
          item.status = ["离线","在线"][item.status];
          item.type = ["中央控制器", "蓝牙网关"][item.type - 1];
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>